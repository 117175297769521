import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input } from "../components";
import { useNavigate } from "react-router-dom";
import { ValidaForm5 } from "../components/Validacao";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const Form5 = () => {
  const [dados, setDados] = useState({});
  const [menor, setMenor] = useState("");
  const [erro, setErro] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDados({ ...dados, [name]: value });
  };

  const setData = async () => {
    await setDoc(doc(db, "furg2025", uid), dados, {
      merge: true,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const err = ValidaForm5(dados);

    if (err) {
      setErro(err);
      setLoading(false);
    } else {
      setData().then(() => navigate("../form6"));
    }
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2025", uid));

    const data = qry.data();

    if (data?.status) {
      navigate("../home");
    } else {
      setDados(data);
      setLoading(false);
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      <Frame
        body={
          <form
            className="my-4"
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
          >
            <div className="row mt-2 d-flex justify-content-center">
              <p>Você já possui 18 anos de idade?</p>

              <div className="d-flex justify-content-between mt-2 w-50">
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => setMenor(true)}
                >
                  Não
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => navigate("../form6")}
                >
                  Sim
                </button>
              </div>
            </div>
            {menor && (
              <div className="text-start">
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <Input
                      id="nomeResponsavel"
                      label="Nome completo do responsável"
                      type="text"
                      className={erro.nomeResponsavel && "is-invalid"}
                      placeholder="Conforme o documento"
                      value={dados?.nomeResponsavel || ""}
                      onChange={handleChange}
                      erro={erro.nomeResponsavel}
                      autoFocus
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-6">
                    <Input
                      id="cpfResponsavel"
                      label="CPF do responsável"
                      type="number"
                      className={erro.cpfResponsavel && "is-invalid"}
                      placeholder="Digite o CPF"
                      value={dados?.cpfResponsavel || ""}
                      onChange={handleChange}
                      erro={erro.cpfResponsavel}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Input
                      id="rgResponsavel"
                      label="RG do Responsável"
                      type="number"
                      className={erro.rgResponsavel && "is-invalid"}
                      placeholder="Digite o RG"
                      value={dados?.rgResponsavel || ""}
                      onChange={handleChange}
                      erro={erro.rgResponsavel}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 d-grid">
                    <button type="submit" className="btn btn-primary">
                      ENVIAR
                    </button>
                  </div>
                </div>
              </div>
            )}
          </form>
        }
        title="Dados Cadastrais"
      />
    </>
  );
};

export default Form5;
