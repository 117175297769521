import { useState, useEffect } from "react";
import { Frame, Loading, Header } from "../components";
import { db, auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

const Contato = () => {
  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2025", uid));

    const data = qry.data();

    setDados(data);
    setLoading(false);
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />

      <Frame
        body={
          <div className="justify">
            <p>Whatsapp Fretamento: (53) 98122-2534</p>
            <p>Telefone: (53) 3278-7000 ramal 233 ou 248</p>
            <p>De segunda à sexta, das 07:30 às 12:00 e das 13:30 às 17:30</p>
          </div>
        }
        title="Fale Conosco"
      />
    </>
  );
};

export default Contato;
