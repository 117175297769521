import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input } from "../components";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

const Inicio = () => {
  const hoje = moment().format("YYYY-MM-DD");

  let min = "2024-09-01";
  if (hoje > min) min = hoje;

  let max = "2024-12-31";

  const [dados, setDados] = useState({});
  const [inicio, setInicio] = useState(min);
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const setData = async () => {
    await setDoc(
      doc(db, "furg2025", uid),
      { dataInicio: inicio },
      { merge: true }
    );
  };

  const handleChange = (e) => {
    setInicio(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setData().then(() => navigate("../aceite"));
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2025", uid));

    const data = qry.data();

    if (data?.status) {
      navigate("../home");
    } else {
      setDados(data);
      setLoading(false);
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header nome={dados?.nome} foto={dados?.urlFoto} />

      {loading && <Loading />}

      <Frame
        body={
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className="m-3"
            noValidate
          >
            <Row>
              <Col sm={12} className="my-2 text-start">
                <Input
                  id="inicio"
                  label="Selecione a data de início do fretamento"
                  type="date"
                  min={min}
                  max={max}
                  value={inicio}
                  onChange={handleChange}
                  autoFocus
                />
              </Col>
            </Row>
            {max > hoje && (
              <Row className="my-2">
                <Col sm={12} className=" d-grid">
                  <button type="submit" className="btn btn-primary">
                    Avançar
                  </button>
                </Col>
              </Row>
            )}
          </form>
        }
        title="Data de Início"
      />
    </>
  );
};

export default Inicio;
