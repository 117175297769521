import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input } from "../components";
import { useNavigate } from "react-router-dom";
import { ValidaForm2 } from "../components/Validacao";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const Form2 = () => {
  const [dados, setDados] = useState({});
  const [erro, setErro] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDados({ ...dados, [name]: value });
  };

  const setData = async () => {
    await setDoc(doc(db, "furg2025", uid), dados, { merge: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const err = ValidaForm2(dados);

    if (err) {
      setErro(err);
      setLoading(false);
    } else {
      setData().then(() => navigate("../form3"));
    }
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2025", uid));

    const data = qry.data();

    if (data?.status) {
      navigate("../home");
    } else {
      setDados(data);
      setLoading(false);
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  const getAddress = () => {
    setDados({
      ...dados,
      endereco2: dados?.endereco1 || "",
      numero2: dados?.numero1 || "",
      bairro2: dados?.bairro1 || "",
      cep2: dados?.cep1 || "",
      complemento2: dados?.complemento1 || "",
    });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      <Frame
        body={
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className="text-start"
            noValidate
          >
            <div className="row mt-2">
              <div className="col-sm-12">
                <Input
                  id="endereco2"
                  label="Endereço de Embarque"
                  type="text"
                  className={erro.endereco2 && "is-invalid"}
                  placeholder="Digite seu endereço"
                  value={dados?.endereco2 || ""}
                  onChange={handleChange}
                  erro={erro.endereco2}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-4">
                <Input
                  id="numero2"
                  label="Número"
                  type="text"
                  className={erro.numero2 && "is-invalid"}
                  placeholder="000"
                  value={dados?.numero2 || ""}
                  onChange={handleChange}
                  erro={erro.numero2}
                />
              </div>
              <div className="col-sm-4">
                <Input
                  id="bairro2"
                  label="Bairro"
                  type="text"
                  className={erro.bairro2 && "is-invalid"}
                  placeholder="Digite o bairro"
                  value={dados?.bairro2 || ""}
                  onChange={handleChange}
                  erro={erro.bairro2}
                />
              </div>
              <div className="col-sm-4">
                <Input
                  id="cep2"
                  label="CEP"
                  type="number"
                  className={erro.cep2 && "is-invalid"}
                  placeholder="Digite o CEP"
                  value={dados?.cep2 || ""}
                  onChange={handleChange}
                  erro={erro.cep2}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12">
                <Input
                  id="complemento2"
                  label="Complemento"
                  type="text"
                  className={erro.complemento2 && "is-invalid"}
                  placeholder="Ponto de referência (opcional)"
                  value={dados?.complemento2 || ""}
                  onChange={handleChange}
                  erro={erro.complemento2}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="text-end">
                <button
                  className="btn btn-outline-primary"
                  onClick={getAddress}
                  type="button"
                >
                  Usar dados do cadastro
                </button>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12 d-grid mt">
                <button type="submit" className="btn btn-primary">
                  ENVIAR
                </button>
              </div>
            </div>
          </form>
        }
        title="Embarque"
      />
    </>
  );
};

export default Form2;
